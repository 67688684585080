
import Vue from 'vue';
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';

import OrganismDateSelection from '@/components/organisms/ride-intent/OrganismDateSelection.vue';
import DateValidations from '@/enums/date/DateValidationEnum';
import { TripFrequency } from '@/store/modules/ride-intent/state';

export default Vue.extend({
  name: 'OrganismReturnTripDateSelection',
  components: {
    OrganismDateSelection,
  },
  data: () => ({
    step: 30,
    slots: 6,
  }),
  computed: {
    ...mapState('rideIntent', ['returnTrip']),
    ...mapGetters('rideIntent', ['getOtherDetails', 'isEditMode']),
    departureQuickSlots(): any[] {
      const { step, slots, returnTrip: { earliestDeparture } } = this;
      const startTime = earliestDeparture.clone().subtract(2 * step, 'minutes');

      return this.generateSlots(startTime, step, slots);
    },
    arrivalQuickSlots(): any[] {
      const { step, slots, returnTrip: { latestArrival } } = this;
      const startTime = latestArrival.clone().subtract(2 * step, 'minutes');

      return this.generateSlots(startTime, step, slots);
    },
    isCalendarValid(): boolean {
      return !(this.returnTrip.validationErrors === DateValidations.RETURN_TRIP_BEFORE_OUTBOUND);
    },
    isRecurrenceMode(): boolean {
      return this.getOtherDetails.repeat.frequency === TripFrequency.RECURRENT;
    },
  },
  methods: {
    ...mapActions('rideIntent', [
      'setReturnTripEarliestDeparture',
      'setReturnTripLatestArrival',
    ]),
    generateSlots(date: moment.Moment, step: number, slots: number) {
      return Array(slots).fill(undefined).map((_, index: number) => ({
        displayName: 'test',
        time: date.clone().add(step * index, 'minutes'),
      }));
    },
  },
});
